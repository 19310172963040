<template>
    <div class="content">
        <div style="text-align:center;">
            <img  src="https://data.chinaecono.com/images/logo.png?t=1683356233">
        </div>
        <div class="loading-item">
            <div style="margin-bottom:10px;background: #FDF5E6;padding: 10px;">
                <i class="iconfont ec-icon-warning" style="color:#e6a23c;font-size:28px;vertical-align:middle;"></i>
                <span class="loading-text">请注意您的账号和财产安全</span>
            </div>
            <div>
                <span style="font-size:14px;line-height:22px;">
                    您即将离开ECONO，去往：<span style="color:#e6a23c;">{{$route.query.target}}</span> 
                </span>
            </div>
            <div style="text-align:right;">
                <el-link  :href="$route.query.target">继续访问</el-link>
            </div>
        </div>
    </div>
</template>
<style lang='scss' scoped>
@import '@/styles/variables.scss';
.content {
    padding-top: 160px;
    width: 450px;
    margin: auto;
    word-break: break-all;
}
.content .loading-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
    margin-left: 12px;
    margin-bottom: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.content .loading-tip {
    padding: 12px;
    margin-bottom: 16px;
    border-radius: 4px;
}
.content .loading-topic {
    font-size: 14px;
    color: #222226;
    line-height: 24px;
    margin-bottom: 24px;
}
.content .loading-item {
    background: #fff;
    padding: 24px;
    border-radius: 6px;
    border: 1px solid #E1E1E1;
}
::v-deep .el-link.el-link--default {
    color: $specialtext-color;
}
::v-deep .el-link.el-link--default:hover {
    color:darken($specialtext-color,10%);
}
</style>